import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/@fortawesome+fontawesome-svg-core@6.5.2/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/driver.js@1.3.1/node_modules/driver.js/dist/driver.css");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/app/[lang]/globals.css");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/app/[lang]/index.css");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/next@15.0.3_react-dom@19.0.0-rc.1_react@19.0.0-rc.1__react@19.0.0-rc.1_sass@1.77.6/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/next@15.0.3_react-dom@19.0.0-rc.1_react@19.0.0-rc.1__react@19.0.0-rc.1_sass@1.77.6/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/next@15.0.3_react-dom@19.0.0-rc.1_react@19.0.0-rc.1__react@19.0.0-rc.1_sass@1.77.6/node_modules/next/font/google/target.css?{\"path\":\"app/[lang]/layout.tsx\",\"import\":\"Noto_Sans_SC\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/buildhome/repo/node_modules/.pnpm/sonner@1.5.0_react-dom@19.0.0-rc.1_react@19.0.0-rc.1__react@19.0.0-rc.1/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/public/logo_x.png");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/public/text_bg.png");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/analytics/google.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/coming-soon/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/echarts/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/global-command/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/motion-animate/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DatePickerProForm"] */ "/opt/buildhome/repo/src/components/pro-form/form-form-item/date-picker/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InputNumberProForm","InputProForm"] */ "/opt/buildhome/repo/src/components/pro-form/form-form-item/input/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SelectProForm"] */ "/opt/buildhome/repo/src/components/pro-form/form-form-item/select/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SwitchProForm"] */ "/opt/buildhome/repo/src/components/pro-form/form-form-item/switch/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TextareaProForm"] */ "/opt/buildhome/repo/src/components/pro-form/form-form-item/textarea/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/pro-form/form-instance/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/pro-form/reset-button/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/pro-form/submit-button/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/pro-form/utils/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["QueryWrapper"] */ "/opt/buildhome/repo/src/components/query-wrapper/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/side-nav-bar/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/toolbar/components/account-button/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/toolbar/components/appsite-badge/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/toolbar/components/fps-badge/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/toolbar/components/language-button/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/toolbar/index.module.css");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/wrappers/index.tsx");
